.head-bg {
    background-color: #fff !important;
    padding: 15px 0 5px;
}

.header-list {
    gap: 15px !important;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.header-list li {
    width: fit-content;
    white-space: nowrap;
}

.header-list li a {
    text-decoration: none;
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.logo-bar.header-logo {
    margin-top: 13px;
}

.logo-bar.header-logo {
    margin-top: 13px;
}

.btn-direct li a:nth-child(2) {
    background: rgba(0, 159, 245, 1);
}



.get-start a {
    color: #fff !important;
    background: rgba(0, 159, 245, 1);
    border-radius: 8px;
    display: inline-block;
    padding: 12px 25px !important;
}

.close-icon {
    text-align: right;
    padding: 0 25px 0 0;
    display: none;
}

body.scrolled .fixed-header {
    animation: slideDown .6s ease-out;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: #fff;
    box-shadow: 0 5px 16px #53d6ff14;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    padding: 12px 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

@keyframes slideDown {
    0% {
        transform: translateY(-90%);
    }

    100% {
        transform: translateY(0);
    }
}