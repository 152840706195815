.indivi-appbar {
    background-color: rgba(255, 255, 255, 0.80) !important;
}

.indi-h-lgo img {
    /* width: 110px; */
    /* height: 20px; */

}

.ovrvi {
    color: #000;

    /* Semibold/Type@14 */
    /* font-family: "Plus Jakarta Sans"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 21px */
    letter-spacing: -0.28px;
}

a.activeH2 {
    border-radius: 10px;
    background: var(--main, #1B7CE5);
    padding: 10px 15px;
}


a.activeH2 .ovrvi {
    color: #fff;
}

a.activeH2 svg path {
    stroke: #fff !important;
}

.outer {
    border-radius: 100px;
    border: 1px solid var(--main, #1B7CE5);
    padding: 12px;

}

/* From Uiverse.io by vinodjangid07 */
.Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: #1B7CE5;
}

/* plus sign */
.sign {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign svg {
    width: 17px;
}

.sign svg path {
    fill: white;
}

/* text */
.text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    transition-duration: .3s;
}

/* hover effect on button width */
.Btn:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
}

.Btn:hover .sign {
    width: 30%;
    transition-duration: .3s;
    padding-left: 20px;
}

/* hover effect button's text */
.Btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
}

/* button click effect*/
.Btn:active {
    transform: translate(2px, 2px);
}