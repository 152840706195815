.overview_body {
    max-width: 1310px;
    margin-top: 40px !important;
    margin: auto;
}

.overview_body .dcard-main {
    width: 68%;
}

.overview_body .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    opacity: 1;
    background-color: #1b7ce5 !important;
}

.overview_body .margin-top.bor table thead tr th:first-child {
    position: relative;
}

/* .overview_body .margin-top.bor table thead tr th:first-child::after {
    position: absolute;
    content: "";
    background: #e1e5ea;
    width: 0.7px;
    height: 356px;
    right: 0;
} */

.ht {
    height: 300px;
    /* border-right: 1px solid #000; */
    color: #586073 !important;
    font-size: 17.795px !important;
    font-weight: 400 !important;
}

.lvlset {
    font-size: 12.456px;
    font-style: normal;
    font-weight: 500;
}

.textflow button {
    color: #373D4D;
    text-align: center;
    font-size: 12.456px;
    font-weight: 500;
    text-transform: capitalize;
}

.dcard-desx {
    font-weight: 500 !important;
}

.overview_body .helpmain {
    width: 190px;
    margin: 35px;
}

img.img-proof {
    width: 30%;
    height: auto;
}

.verify {
    color: #25C348;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.not-verify {
    color: #d32f2f;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}