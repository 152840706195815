.country.d-flex {
    display: flex;
    gap: 9px;
}

.session-btn {
    border-radius: 3.595px !important;
    background: #D3E2FF !important;
    color: #0A2D73 !important;
    font-family: Inter !important;
    font-size: 10.785px !important;
    font-weight: 500 !important;
    line-height: 14.381px !important;
    justify-content: flex-start !important;
    text-transform: initial !important;
    width: fit-content !important;
}

.flex-line-gap {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.f-500 {
    font-weight: 500 !important;
}

.td-grey-text {
    color: #586073;
    font-size: 10.785px;
    font-weight: 400;
    line-height: 17.976px;
}

.item-list-table h2 {
    color: #212736;
    font-size: 16.178px;
    font-weight: 700;
    line-height: 21.571px;
}

.profile-tab-outer-div h1 {
    color: #212736;
    font-size: 17.976px;
    font-weight: 700;
    /* line-height: 26.964px; */
    letter-spacing: -0.18px;
}

.profile-set-head label {
    line-height: 9px;
    justify-content: flex-start;
}

.profile-lang {
    width: 273.233px;
}

.profile-lang .select-item-list {
    width: 273.233px;
}

.profile_tab button {
    color: #7D8799;
    text-align: center;
    font-family: Inter;
    font-size: 12.583px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.571px;
    text-transform: capitalize;
    width: fit-content;
    position: relative;
}

.profile_tab button.Mui-selected {
    color: #1764FF !important;
}

.profile_tab button.Mui-selected::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    background: rgba(255, 255, 255, 0.00);
    box-shadow: 0px -1.798px 0px 0px #1764FF inset;
    bottom: 0;
}

.profile-tab-update-inner h6 {
    color: #212736;
    font-family: Inter;
    font-size: 16.178px;
    font-weight: 400;
    line-height: 21.571px;
}

.profile-tab-update-inner span {
    color: #373D4D;
    font-family: Inter;
    font-size: 12.583px;
    font-weight: 400;
    line-height: 21.571px;
}

.profile-tab-update-inner button {
    color: #1764FF;
    text-align: center;
    font-family: Inter;
    font-size: 12.583px;
    font-weight: 500;
    line-height: 21.571px;
    padding-left: 0;
    justify-content: flex-start;
}

.profile-tab-update-inner {
    border-bottom: 0.899px solid #E1E5EA;
    width: 359px;
    min-height: 104px;
}

.enabled-button {
    border: 1px solid #008000 !important;
    color: #008000 !important;
    padding: 6px 8px !important;
}

.disabled-button {
    border: 1px solid #ff0000 !important;
    color: #ff0000 !important;
    padding: 6px 8px !important;
}

.add-edit {
    align-items: flex-start;
    gap: 32px;
}

.edit_btn svg,
.edit_btn svg:focus,
.edit_btn svg:focus-visible {
    color: #1764FF !important;
}

.edit_btn a {
    color: #19BC9B;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.5px;
}

.profile_flex_input .email-filed label {
    width: -webkit-fill-available;
}

.enable_btn {
    margin-top: 10px;
}

.profile_flex_input .divider {
    background: transparent !important;
    margin: 30px 0;
    height: 0 !important;
    border-top: 0.1px solid #E1E5EA;
}

.first_inputs.display-1 div:first-child {
    width: -webkit-fill-available;
}

.first_inputs.display-1 {
    align-items: flex-start;
}

.first_inputs.display-1>div:last-child {
    margin-top: 71px;
}

.profile_pic img {
    width: 115px !important;
    height: 123px !important;
    border-radius: 15px;
}

.change_pic label {
    color: #1764FF;
    text-align: center;
    font-size: 12.583px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.571px;
    text-transform: capitalize;
    min-height: 56px;
    width: fit-content;
}

.change_pic .cursor {
    border-bottom: 0.899px solid #E1E5EA;
    width: 359px !important;
}

.profile-tab-update-inner.mt-5 {
    margin-top: 30px;
}

.create-modal.text-end {
    margin: 25px 0 15px;
}

.apikey-table table tr td svg {
    width: 30px;
    height: 20px;
}

.security-Code {
    word-break: break-all;
}

.verif-2fa-digit-inner input {
    padding: 10px !important;
}

.verif-2fa-digit-inner button {
    padding: 8.5px 15px !important;
}

.close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.api-table {
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* From Uiverse.io by mobinkakei */
.toggler {
    width: 72px;
    margin: 40px auto;
}

.toggler input {
    display: none;
}

.toggler label {
    display: block;
    position: relative;
    width: 72px;
    height: 36px;
    border: 1px solid #d6d6d6;
    border-radius: 36px;
    background: #e4e8e8;
    cursor: pointer;
}

.toggler label::after {
    display: block;
    border-radius: 100%;
    background-color: #d7062a;
    content: '';
    animation-name: toggler-size;
    animation-duration: 0.15s;
    animation-timing-function: ease-out;
    animation-direction: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
}

.toggler label::after,
.toggler label .toggler-on,
.toggler label .toggler-off {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 26px;
    height: 26px;
    transform: translateY(-50%) translateX(-50%);
    transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

.toggler input:checked+label::after,
.toggler input:checked+label .toggler-on,
.toggler input:checked+label .toggler-off {
    left: 75%;
}

.toggler input:checked+label::after {
    background-color: #50ac5d;
    animation-name: toggler-size2;
}

.toggler .toggler-on,
.toggler .toggler-off {
    opacity: 1;
    z-index: 2;
}

.toggler input:checked+label .toggler-off,
.toggler input:not(:checked)+label .toggler-on {
    width: 0;
    height: 0;
    opacity: 0;
}

.toggler .path {
    fill: none;
    stroke: #fefefe;
    stroke-width: 7px;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

@keyframes toggler-size {

    0%,
    100% {
        width: 26px;
        height: 26px;
    }

    50% {
        width: 20px;
        height: 20px;
    }
}

@keyframes toggler-size2 {

    0%,
    100% {
        width: 26px;
        height: 26px;
    }

    50% {
        width: 20px;
        height: 20px;
    }
}

@media (max-width:991px) {
    .flex-lg-wrap {
        flex-wrap: wrap;
    }
}

.box_align {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.box_align>div {
    margin: 0;
    width: 48%;
}