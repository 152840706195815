.chatBox h5 {
    color: #202224 !important;
    font-size: 22.485px !important;
    font-weight: 700 !important;
    border-bottom: 1px solid #E0E0E0;
    padding:22px 30px;
}
.chatBox h5 span {
    color: #D456FD;
    font-size: 13.491px;
    font-weight: 600;
    padding: 3px 10px;
    border-radius: 3.373px;
    background: #d456fd2b;
}
.chatBox .ChatBox_grid_box {
    border-radius: 15.739px;
    border: 0.337px solid #B9B9B9;
    background: #FFF;
    box-shadow: none !important;
    padding: 0;
}
.send_btn{
    border-radius: 6.745px !important;
    width: 107.927px;
    height: 38.224px;
    background: #4880FF !important;
    color: #FFF;
    text-align: right !important;
    font-size: 13.491px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.send_btn svg{
    font-size: 13.491px !important;
}
.support-left-main{
    justify-content: flex-end !important;
}
.chat_textField fieldset{
    border: 0 !important;
}
.chat_send_div{
    align-items: center;
}
.chat_send_div {
    padding: 0px 25px 15px;
}
.ChatBox_grid_box li.MuiListItem-root {
    /* border-radius: 17.988px 17.988px 0px 17.988px;
    background: #4880FF; */
    width: 665.553px;
    margin-bottom: 44px;
    float: inline-end;
    align-items: end;   
}
.ChatBox_grid_box ul.MuiList-root{
    padding: 30px;
    scroll-behavior: smooth;
}
.ChatBox_grid_box .MuiListItemText-root p{
    width: 506.553px;
    word-break: break-word;
    color: #FFF;
    font-size: 15.739px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.982px;
    letter-spacing: 0.112px;
}
ul.userTwoChat li.MuiListItem-root.MuiListItem-gutters {
    float: left;
}
li.userTwoChat {
    display: flex;
    width: 665.553px;
}
.current_time {
    color: #FFF !important;
    text-align: right;
    font-size: 13.491px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: right;
}
.sender{
    color: #fff !important;
}
.sender_chat{
    border-radius: 17.988px 17.988px 0px 17.988px;
    background: #4880FF;
    width: 665.553px;
    padding: 25px;
}
.userTwoChat{
    align-items: end;
}
.userTwoChat .MuiListItemText-root {
    border-radius: 17.988px 17.988px 17.988px 0px;
    background: #F5F5F5;
    padding: 25px;
}
.userTwoChat .sender, .userTwoChat .current_time {
    color: #d456fd !important; 
    width: fit-content !important;
}
.receiver_chat{
    color: #202224 !important;
    font-size: 15.739px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.23px;
    letter-spacing: 0.112px; 
}