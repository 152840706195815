/* @import '../../Scss/main.scss'; */

.Live-tet {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #B9B9B9;

}

.livechat-banner {
    height: 75vh;
    overflow-y: scroll;

}
.m-0{
    margin:0 !important;
}
.bxshade {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 15px;
    border-radius: 12px;
    padding: 10px;
    /* height: 70%; */
}

.livechat-type {
    position: sticky;
    bottom: 0px;

    /* height: 100vh; */
    /* width: 80%; */
}

.livechat-type .MuiFormControl-root {
    background: #ffffff;
    margin: 10px;
    border-radius: 15px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


}

.livechat-type fieldset {
    display: none;
}

.livechat-type input {
    color: #000;
    /* // padding: 0px; */
    padding: 15px;
}

.tc-name {
    font-size: 15px;
    font-weight: 600;
}

.tc-name-main {
    font-size: 12px;
    font-weight: 400;
}

/* .leftsidechat {} */

.chat-memname {
    /* font-family: Inter; */
    font-size: 20px;
    font-weight: 500;
    line-height: 17.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    padding: 2px;
    text-transform: capitalize;
}

.chat-memname span {
    /* font-family: Inter; */
    font-size: 10px;
    font-weight: 300;
    line-height: 12.1px;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0px 5px;
    color: var(--clr-font);
    text-transform: capitalize;
}

.chat-message {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--clr-font);
    text-transform: capitalize;
    background: var(--clr-pcnew);
    padding: 10px;
    margin-top: 5px;
    border-radius: 0 12px 12px 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.mappedchat {
    margin: 20px 0px;
}

.chat-message.right {
    border-radius: 12px 0px 12px 12px !important;
}

.mappedchat.right {
    justify-content: flex-end !important;
}

.livechathead {
    background: #ffffff2a;
    position: sticky;
    padding: 15px;
    backdrop-filter: blur(5px);
    top: 0;
    z-index: 15;
}

.livechat-type {
    background: var(--clr-hoisting-bg);
    z-index: 15;
    backdrop-filter: blur(5px);
}

.leftsidechat,
.rightsidechat {
    padding: 10px;
}

.display-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.display-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.display-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cursor {
    cursor: pointer;
}

.tickcunt {
    background: red;
    padding: 2px 9px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 13px;
}

.active.display-2 {
    background: #009FF5;
    margin: 15px 0px;
    padding: 15px;
    border-radius: 12px;
    color: #fff !important;
}

.display-2.nonactive {
    /* // background: var(--clr-bg); */
    margin: 15px 0px;
    padding: 15px;
    border-radius: 12px;
}

.email-filed1 .MuiFormControl-root {
    width: 100%;
}

.email-filed1 {
    margin: 10px 0;
}

.reason-txt {
    text-align: center;
    font-weight: bolder;
    font-size: larger;
    color: red;
}

.maincreate {
    font-size: 50px !important;
}

.createtk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    cursor: pointer;
}