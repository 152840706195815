.login-bg h1 {
    font-size: clamp(25px, 5vw, 32px);
    font-weight: 700;
    line-height: 1;
    text-align: var(--center);
    margin-bottom: 0;
}

.login-bg p {
    font-size: 16px;
    font-weight: 400;
    text-align: var(--center);
    max-width: 80%;
    margin: 10px auto;
    color: rgba(88, 96, 115, 1);
}

.login-bg {
    max-width: 500px;
    margin: 0px auto;
    background: rgba(255, 255, 255, 1);
    box-shadow: 3px 27px 10.6px 5px rgb(0 0 0 / 13%);
    padding: 75px;
    padding: clamp(25px, 5vw, 75px);
    border-radius: 10px;
    padding-bottom: 8px;
    padding-top: 20px;
}

.login-full.reg .login-bg {
    overflow: scroll;
    height: 750px;
    margin-top: 0px !important;
}
.login-full.reg{
    padding-top: 20px;
    overflow: visible !important;
}
.login-full.reg .login-bg::-webkit-scrollbar {
    width: 0;
}

.login-full.reg .login-bg:hover::-webkit-scrollbar {
    width: 2px;
}

.textfield {
    margin: 10px auto;
    max-width: 400px;
}

.email-filed label {
    font-size: 14px;
    font-weight: 500;
    gap: 6px;
}

.email-filed {
    text-align: var(--left);
    margin-bottom: 15px;
}

.email-filed label svg {
    width: 16px;
    fill: rgba(125, 135, 153, 1);
}

.email-filed .MuiInputBase-root {
    /* margin-top: 8px; */
    height: 43px;
}

.email-filed fieldset {
    /* border: 1px solid rgba(203, 207, 216, 1); */
    border: 1px solid rgb(203 207 216 / 34%);
    border-radius: 7px !important;
}

/* .submit-button button {
    background: var(--color-Blue);
    height: 36px;
    padding: 14px 0 0;
    border-radius: 5px;
    margin-top: 25px;
    text-align: var(--center);
} */

.submit-button button {
    color: #fff !important;
    text-decoration: none;
    background: var(--color-Blue) !important;
    border-radius: 5px;
    /* height: 36px; */
    margin-top: 15px;
    /* padding: 14px 0 0; */
    text-align: var(--center);
    display: inline-block;
    width: 100%;
}

.or-col {
    position: var(--position-rel);
    text-align: var(--center);
}

.or-col:after {
    position: var(--position-abs);
    width: 100%;
    content: '';
    background: rgba(211, 215, 223, 1);
    height: 2px;
    left: 0;
    top: 10px;
}

.or-col span {
    background: #fff;
    z-index: 1;
    position: var(--position-rel);
    padding: 7px;
    color: rgba(88, 96, 115, 1);
}

.sign-up-google {
    margin-top: 15px;
    border: 1px solid rgba(225, 229, 234, 1);
    padding: 7px;
    border-radius: 8px;
    gap: 6px;
    justify-content: center !important;
}

.sign-up-google a {
    color: rgba(55, 61, 77, 1);
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
}

.privacy-note p {
    font-size: 12px;
    text-align: left;
    margin: 15px 0;
}

.copy-rght {
    margin-top: 15px;
    text-align: var(--center);
}

.copy-rght p {
    margin-bottom: 5px;
    color: rgba(88, 96, 115, 1);
    font-size: 14px;
}

.copy-rght a {
    color: rgba(88, 96, 115, 1);
}

.sign-up-google.display-1 img {
    margin-top: 6px;
}

.disclamier li {
    padding: 0px 6px 0px;
    gap: 5px;
    font-size: 13px;
}

.disclamier svg {
    width: 19px;
    color: rgba(192, 55, 68, 1);
}

.disclamier ul {
    padding-top: 0 !important;
}

.register-full .login-bg {
    margin: 75px auto;
}

.signin-full {
    /* margin-top: 100px !important; */
    height: 100vh;
}

.signin-left h2 {
    max-width: 460px;
    font-size: clamp(25px, 5vw, 32px);
    color: rgba(0, 159, 245, 1);
    font-weight: var(--weight-600);
}

.signin-left p {
    max-width: 444px;
    line-height: 28px;
    font-size: 16px;
    margin-top: 48px;
}

.signin-left h5 {
    font-size: clamp(25px, 5vw, 32px);
    font-weight: var(--weight-600);
    margin-top: 0;
}

.logo-bar,
.signin-full .privacy-note p {
    text-align: var(--center);
}

.signin-full .login-bg {
    margin-top: 0;
    max-width: 500px;
    padding: clamp(25px, 5vw, 56px);
    padding-bottom: 56px;
}

.signin-full .login-bg h1 {
    margin-top: 50px;
    font-size: clamp(35px, 5vw, 48px);
    font-weight: var(--weight-600);
}

.activate .MuiInputBase-root {
    height: 45px;
    width: 45px;
}

.back-btn a {
    display: flex;
    gap: 10px;
    color: rgba(46, 116, 255, 1);
    align-items: center;
}

.back-btn a svg {
    font-size: 16px;
}

;

.login-full.activate .login-bg {
    padding-top: 55px;
}

input#fullWidth:-webkit-autofill,
input#outlined-adornment-password:-webkit-autofill {
    border-radius: inherit;
    padding: 10px;
}

.login-full.activate {
    overflow: hidden;
    height: 100vh;
}

.tick {
    color: green !important;
}

.email-sub {
    font-size: 16px !important;
    padding: 15px 0 5px 5px;
}

.email-filed.sub fieldset {
    border: 1px solid rgba(116, 119, 126, 0.34);
    border-radius: 7px !important;
}

.sender-name {
    font-size: 14px;
    font-weight: 600;
}