/* From Uiverse.io by iSweat-exe */
.card {
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    max-width: 320px;
    border-radius: 20px;
    width: 90%;
    margin: auto;
}

.title {
    display: flex;
    align-items: center;
}

.title span {
    position: relative;
    padding: 0.5rem;
    background-color: #009FF5;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
}

.title span svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    height: 1rem;
}

.title-text {
    margin-left: 0.5rem;
    color: #374151;
    font-size: 18px;
}

.percent {
    margin-left: 0.5rem;
    color: #009FF5;
    font-weight: 600;
    display: flex;
}

.data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.data p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #1F2937;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    text-align: left;
}

.data .range {
    position: relative;
    background-color: #E5E7EB;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
}

.data .range .fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #009FF5;
    width: 36%;
    height: 100%;
    border-radius: 0.25rem;
    transition: .5s all;
}

/* From Uiverse.io by andrew-demchenk0 */
.info {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 320px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    background: #509AF8;
    border-radius: 8px;
    box-shadow: 0px 0px 5px -3px #111;
}

.info__icon {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
    margin-right: 8px;
}

.info__icon path {
    fill: #fff;
}

.info__title {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

.info__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
}

.info__close path {
    fill: #fff;
}

@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow);

body {
    font-family: Open Sans, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #666;
    position: relative;
    -webkit-font-smoothing: antialiased;
    margin: 0;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
    /* font-size: 13px; */
    direction: ltr;
}

.sectionClass {
    padding: 20px 0px 50px 0px;
    position: relative;
    display: block;
}

.fullWidth {
    width: 100% !important;
    display: table;
    float: none;
    padding: 0;
    min-height: 1px;
    height: 100%;
    position: relative;
}


.sectiontitle {
    background-position: center;
    margin: 30px 0 0px;
    text-align: left;
    min-height: 20px;
}

.sectiontitle h2 {
    font-size: 30px;
    color: #222;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
}


.headerLine {
    width: 160px;
    height: 2px;
    display: inline-block;
    background: #101F2E;
}


.projectFactsWrap {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 15px;
    overflow: hidden;
}


#projectFacts .fullWidth {
    padding: 0;
}

.projectFactsWrap .item {
    width: 25%;
    height: 100%;
    padding: 50px 0px;
    text-align: center;
}

.projectFactsWrap .item:nth-child(1) {
    background: rgb(16, 31, 46);
}

.projectFactsWrap .item:nth-child(2) {
    background: rgb(18, 34, 51);
}

.projectFactsWrap .item:nth-child(3) {
    background: rgb(21, 38, 56);
}

.projectFactsWrap .item:nth-child(4) {
    background: rgb(23, 44, 66);
}

.projectFactsWrap .item p.number {
    font-size: 40px;
    padding: 0;
    font-weight: bold;
}

.projectFactsWrap .item p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin: 0;
    padding: 10px;
    font-family: 'Open Sans';
}


.projectFactsWrap .item span {
    width: 60px;
    background: rgba(255, 255, 255, 0.8);
    height: 2px;
    display: block;
    margin: 0 auto;
}


.projectFactsWrap .item i {
    vertical-align: middle;
    font-size: 50px;
    color: rgba(255, 255, 255, 0.8);
}


.projectFactsWrap .item:hover i,
.projectFactsWrap .item:hover p {
    color: white;
}

.projectFactsWrap .item:hover span {
    background: white;
}

@media (max-width: 786px) {
    .projectFactsWrap .item {
        flex: 0 0 50%;
    }
}

/* AUTHOR LINK */


footer {
    z-index: 100;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    bottom: 0;
    left: 0;
}

footer p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    opacity: 0;
    font-family: 'Open Sans';
    width: 100%;
    word-wrap: break-word;
    line-height: 25px;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
    margin: 0;
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    transition: all 250ms ease;
}

footer .authorWindow a {
    color: white;
    text-decoration: none;
}

footer p strong {
    color: rgba(255, 255, 255, 0.9);
}

.about-me-img {
    width: 120px;
    height: 120px;
    left: 10px;
    /* bottom: 30px; */
    position: relative;
    border-radius: 100px;
}


.about-me-img img {}


.authorWindow {
    width: 600px;
    background: #75439a;
    padding: 22px 20px 22px 20px;
    border-radius: 5px;
    overflow: hidden;
}

.authorWindowWrapper {
    display: none;
    left: 110px;
    top: 0;
    padding-left: 25px;
    position: absolute;
}





.trans {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
}

@media screen and (max-width: 768px) {
    .authorWindow {
        width: 210px;
    }

    .authorWindowWrapper {
        bottom: -170px;
        margin-bottom: 20px;
    }

    footer p {
        font-size: 14px;
    }
}

.overviewtable {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    background-color: #fff !important;
    border-radius: 15px !important;
}

.overviewtable th,
.overviewtable td {
    border-bottom: none;
}