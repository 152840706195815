section.banner-sctn h1,
section.product h2,
section.industies h1 {
  text-align: var(--center);
  font-size: clamp(40px, 5vw, 80px);
  margin: 15px 0 25px;
}

section.banner-sctn h2 {
  text-align: var(--center);
  margin-bottom: 15px;
  font-size: clamp(30px, 5vw, 20px);
}

section.banner-sctn {
  padding-top: 150px;
}

.verfication-btn {
  justify-content: var(--center);
  gap: 15px;
}

.verfication-btn a {
  padding: 16px 40px;
  text-decoration: none;
  border-radius: 8px;
  color: var(--color-Blue);
  border: 1px solid rgba(0, 159, 245, 1);
  position: var(--position-rel);
}

.verfication-btn a:first-child {
  background: rgba(0, 159, 245, 1);
  color: #fff;
  display: var(--flex);
  gap: 10px;
  align-items: var(--center);
}

.verfication-btn a svg {
  transform: rotate(87deg);
  width: 23px;
}

.verfication-btn a:hover {
  background: rgba(0, 159, 245, 1);
  color: #fff;
}

.verfication-btn a:hover svg {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: rotate(87deg) translateY(0);
  }

  40% {
    transform: rotate(87deg) translateY(-10px);
    /* Adjust the bounce height */
  }

  60% {
    transform: rotate(87deg) translateY(-5px);
    /* Adjust the bounce height */
  }
}

.banner-img {
  margin-top: 35px;
  text-align: var(--center);
}

.banner-img img {
  max-width: 100%;
  height: auto;
}

.exchange-swiper .swiper-part {
  gap: 30px;
  margin: 40px 0 20px 0;
}

.swiper-part {
  display: var(--flex);
  white-space: nowrap;
  animation: 60s slides infinite linear;
}

@keyframes slides {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.exchange-swiper {
  overflow: hidden;
  max-width: 1200px;
  margin: 10px auto;
}

.swiper-part-two {
  display: var(--flex);
  white-space: nowrap;
  gap: 40px;
  animation: 100s slideright infinite linear;
}

@keyframes slideright {
  0% {
    transform: translateX(-40%);
  }

  100% {
    transform: translateX(40%);
  }
}

.exchange-swiper .swiper-part:hover,
.exchange-swiper .swiper-part-two:hover {
  animation: paused;
  cursor: pointer;
}

.banner-info {
  text-align: var(--center);
}

section.product h2 {
  font-weight: 600;
  margin-bottom: 80px;
}

.verification-img {
  display: var(--flex);
  align-items: var(--center);
  gap: 15px;
}

.verification-img h5 {
  font-size: clamp(25px, 5vw, 35px);
  margin: 0;
  font-weight: var(--weight-600);
}

.user-verification p {
  max-width: 320px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.business img {
  box-shadow: 0px 29.59px 73.15px 0px rgba(0, 0, 0, 0.04);
  max-width: 100%;
  height: auto;
}

.crypto-card .MuiCardContent-root {
  padding: 75px 50px 25px;
}

.industry-para h6 {
  font-size: 25.09px;
  font-weight: 600;
  margin: 0 0 10px;
}

.industry-para p {
  font-size: 14.11px !important;
  font-weight: 400 !important;
  line-height: 20.38px;
  padding-bottom: 25px;
}

.turnkey-box {
  background: rgba(246, 245, 244, 1);
  padding: clamp(35px, 5vw, 75px);
  border-radius: 23px;
}

.turnkey-box p {
  font-size: clamp(16px, 5vw, 22px);
  font-weight: 500;
  max-width: 1103px;
  margin: 0 auto;
}

.crypto-card {
  margin-top: 50px;
}

.crypto-card .MuiPaper-root {
  box-shadow: none;
  border-radius: 23px;
  height: 100%;
}

.crypto-card {
  max-width: 1050px;
  margin: 50px auto;
}

.crypto-card .MuiGrid-root {
  justify-content: var(--center);
}

.industry-para img {
  margin-bottom: 30px;
}

section.industies {
  position: var(--position-rel);
  padding-top: 70px;
}

.industry-banner img {
  max-width: 100%;
  height: auto;
  width: 250px;
}

.industry-banner {
  position: var(--position-abs);
  top: 50px;
  left: 150px;
}

section.full-security {
  background: rgba(217, 241, 253, 1);
  margin-top: 75px;
  border-radius: 50px;
  padding: 50px;
}

section.full-security h2 {
  font-size: clamp(25px, 5vw, 48px);
  font-weight: 600;
  max-width: 300px;
  margin: 0;
}

.compliance-brand img {
  width: 118px;
  max-width: 65%;
  height: auto;
}

.banner-info img {
  max-width: 100%;
  height: auto;
}

.brand-logo {
  gap: clamp(15px, 5vw, 20px);
  flex-wrap: wrap;
  width: 89%;
}

.verfify-img img {
  max-width: 100%;
  width: 150px;
  height: auto;
}

section.verifys {
  margin-top: 75px;
  position: relative;
}

.verify-box {
  background: rgba(246, 245, 244, 1);
  padding: 45px 45px 10px;
  border-radius: 15px;
  max-width: 1100px;
}

.verify-box p {
  max-width: 473px;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 50px;
}

.verify-box h2 {
  font-size: clamp(25px, 5vw, 40px);
  font-weight: 600;
  max-width: 335px;
}

.detail-img {
  position: absolute;
  right: 250px;
  top: 75px;
}

.detail-img img {
  max-width: 100%;
  height: auto;
}

.secure-width {
  max-width: 750px;
  margin: 75px auto;
  text-align: center;
}

section.secure-sctn h2 {
  font-size: clamp(25px, 5vw, 48px);
  font-weight: 600;
  max-width: 550px;
  margin: 0 auto;
}

.sign-brand img {
  width: 26px;
  height: 26px;
}

.whole-secure {
  max-width: 450px;
  margin: 60px auto;
}

.sign-card {
  background: rgba(246, 245, 244, 1);
  padding: 25px;
  text-align: left;
  border-radius: 12px;
}

.sign-card h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0;
}

.customer-img {
  position: var(--position-abs);
  top: 43%;
  left: 67%;
}

.customer-img img {
  width: 250px;
}

section.secure-sctn {
  position: var(--position-rel);
}

.landing-full {
  background: #fff;
}

.crypto-card .MuiPaper-root:hover {
  transform: translateY(-12px);
  transition: all 0.3s ease;
}

.select-option {
  display: flex;
  flex-direction: column;
}

.select-option {
  display: flex;
  flex-direction: column;
}

.detail-box a {
  color: #fff;
}

.verfiy-btn {
  background: rgba(0, 17, 51, 1);
  padding: 16px 58px;
  border-radius: 8px;
  margin-top: 35px;
}

div#demo-multiple-name {
  padding: 9.5px 14px !important;
}

.detail-box h6 {
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0 15px;
}

.detail-box {
  box-shadow: 4px 4px 0px 0px rgba(207, 221, 249, 1);
  background: #fff;
  padding: 32px 50px;
  border-radius: 25px;
}

.detail-box .prfsumer-mian.text-left {
  height: auto;
  margin-top: 25px;
}

.detail-box label {
  color: rgba(51, 60, 77, 1);
  font-size: 12px;
  padding-top: 15px;
}
